import React from 'react';

const TermsAndConditions = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1><strong>Terms and Conditions</strong></h1>
      <p><em>Last Updated: 29/08/2024</em></p>

      <p>Welcome to Skilled Mates! These terms and conditions outline the rules and regulations for using the Skilled Mates platform.</p>

      <h2><strong>1. Acceptance of Terms</strong></h2>
      <p>
        By accessing or using the Skilled Mates platform, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use our platform.
      </p>

      <h2><strong>2. Platform Description</strong></h2>
      <p>
        Skilled Mates is an online platform that facilitates connections between employers and employees. We provide a space where employers can post job opportunities and employees can search and apply for jobs. Skilled Mates does not act as an employer or employment agency.
      </p>

      <h2><strong>3. Disclaimer of Liability</strong></h2>
      <p>
        Skilled Mates serves only as a platform for connecting employers and employees. We do not endorse or guarantee any job postings, employment opportunities, or users on our platform.
      </p>
      <p><strong>You acknowledge and agree that:</strong></p>
      <ul>
        <li>Skilled Mates is not responsible for any job offers, employment terms, hiring decisions, or employment conditions.</li>
        <li>We are not liable for any disputes, damages, fraud, spam, or other issues arising between employers and employees.</li>
        <li>Users are responsible for verifying the legitimacy and accuracy of any job postings or communications.</li>
      </ul>

      <h2><strong>4. User Responsibilities</strong></h2>
      <p>Users of Skilled Mates, both employers and employees, are responsible for:</p>
      <ul>
        <li>Providing accurate, current, and complete information.</li>
        <li>Complying with all applicable laws and regulations related to employment.</li>
        <li>Performing their own due diligence when considering job offers or candidates.</li>
        <li>Reporting any suspicious activities, fraud, or spam to Skilled Mates immediately.</li>
      </ul>

      <h2><strong>5. Prohibited Activities</strong></h2>
      <p>Users of Skilled Mates agree not to engage in the following activities:</p>
      <ul>
        <li>Posting false, misleading, or fraudulent information.</li>
        <li>Harassing, threatening, or discriminating against other users.</li>
        <li>Using the platform for any unlawful or unauthorized purposes.</li>
        <li>Uploading or distributing malicious software, viruses, or harmful content.</li>
      </ul>

      <h2 className="font-bold text-lg mb-2">6. Privacy and Data Protection</h2>
        <p className="mb-4">
        We respect your privacy and are committed to protecting your personal information. Our Privacy Policy, which outlines how we collect, use, and protect your data, is incorporated into these Terms and Conditions. By using Skilled Mates, you agree to our Privacy Policy.
        </p>
        <p className="mb-4">
        By providing your email, you agree to receive communications from us. Additionally, if you have consented to share your phone number, it may be shared with employers and employees for the purposes of connecting you with job opportunities or filling roles.
        </p>

      <h2><strong>7. Account Termination</strong></h2>
      <p>
        Skilled Mates reserves the right to terminate or suspend any user account at its sole discretion, without notice, for violations of these Terms and Conditions or any other reason deemed necessary.
      </p>

      <h2><strong>8. Intellectual Property</strong></h2>
      <p>
        All content on the Skilled Mates platform, including but not limited to text, graphics, logos, and software, is the property of Skilled Mates and is protected by applicable intellectual property laws. Users may not reproduce, distribute, or create derivative works from any content found on the platform without express permission.
      </p>

      <h2><strong>9. Limitation of Liability</strong></h2>
      <p>
        To the fullest extent permitted by law, Skilled Mates shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the platform. This includes, but is not limited to, loss of profits, data, or other intangible losses.
      </p>

      <h2><strong>10. Indemnification</strong></h2>
      <p>
        You agree to indemnify, defend, and hold harmless Skilled Mates, its affiliates, and their respective officers, directors, employees, and agents from any claims, damages, liabilities, losses, or expenses arising from your use of the platform, violation of these Terms and Conditions, or infringement of any rights of another party.
      </p>

      <h2><strong>11. Modifications to Terms</strong></h2>
      <p>
        Skilled Mates reserves the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on the platform. Your continued use of the platform after any modifications indicates your acceptance of the new Terms and Conditions.
      </p>

      

      <h2><strong>14. Contact Us</strong></h2>
      <p>
        If you have any questions or concerns regarding these Terms and Conditions, please contact us using feedback button.
      </p>

      <p>
        By using Skilled Mates, you acknowledge that you have read, understood, and agree to these Terms and Conditions.
      </p>
    </div>
  );
};

export default TermsAndConditions;
