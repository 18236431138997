import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { BounceLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Icons for success and error
import Pricing from "../components/Pricing";

Modal.setAppElement("#root"); // Ensure accessibility by setting app root for the modal

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "worker",
    location: "",
  });

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showMembershipMessageModal, setShowMembershipMessageModal] =
    useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("success"); // 'success' or 'error'
  const [isFormValid, setIsFormValid] = useState(false); // State to track form validity
  const [isPhoneValid, setIsPhoneValid] = useState(true); // State to track phone validation
  const [suburbs, setSuburbs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSuburbs = async () => {
      if (formData.location.length > 0) {
        setLoading(true);
        try {
          const response = await axiosInstance.get(`/api/suburbs`, {
            params: { query: formData.location },
          });
          setSuburbs(response.data);
          setShowDropdown(true);
        } catch (error) {
          console.error("Error fetching suburb data:", error);
          setSuburbs([]);
          setShowDropdown(false);
        } finally {
          setLoading(false);
        }
      } else {
        setShowDropdown(false);
      }
    };

    const debounceFetch = setTimeout(() => {
      fetchSuburbs();
    }, 300); // Debounce time in milliseconds

    return () => clearTimeout(debounceFetch);
  }, [formData.location]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validatePhone = (phone) => {
    if (!phone) {
      return true;
    }

    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(phone);
  };

  const handleSelect = (suburb) => {
    setFormData((prevData) => ({ ...prevData, location: suburb }));
    setShowDropdown(false);
  };
  // Validate form whenever formData changes
  useEffect(() => {
    const { name, email, phone, password } = formData;

    // Check if all fields are filled and phone is valid
    const isFormFilled = name && email && phone && password;
    const isValidPhone = validatePhone(phone);

    setIsPhoneValid(isValidPhone);
    setIsFormValid(isFormFilled && isValidPhone);
  }, [formData]);

  const handleRoleChange = (e) => {
    setFormData({
      ...formData,
      role: e.target.value,
    });
  };

  const closeModal = () => {
    setShowMessageModal(false);
    if (messageType === "success") {
      navigate("/login"); // Redirect on successful registration
    }
  };

  const closeMembershipModal = () => {
    setShowMembershipMessageModal(false);
    if (messageType === "success") {
      navigate("/login"); // Redirect on successful registration
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/api/register", formData);
      if (formData.role === "employer" || formData.role === "both") {
        setMessage(
          "Registration successful! Next step: Unlock full access with Skilled Mates Membership — post jobs, find staff, and connect instantly. Subscribe now!"
        );
        setMessageType("success");
        setShowMembershipMessageModal(true);
      } else {
        setMessage("Registration successful! You can now log in.");
        setMessageType("success");
        setShowMessageModal(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage("You are already registered, please log in.");
      } else {
        setMessage("An error occurred during registration. Please try again.");
      }
      setMessageType("error");
      setShowMessageModal(true);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Register</h1>
      <form onSubmit={handleSubmit}>
        {/* Form Fields */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your name"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your email"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="phone"
          >
            Phone
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            value={formData.phone}
            onChange={handleChange}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              !isPhoneValid ? "border-red-500" : ""
            }`}
            placeholder="Your phone number"
            required
          />
          {!isPhoneValid && (
            <p className="text-red-500 text-xs italic">
              Phone number must be numeric.
            </p>
          )}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your password"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="location"
          >
            Location
          </label>
          <input
            type="text"
            name="location"
            placeholder="Start typing..."
            value={formData.location}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded"
          />
          {showDropdown && (
            <div className="relative">
              <div className="absolute  bg-white border border-gray-300 rounded shadow-lg w-full mt-1 max-h-60 overflow-auto">
                {loading ? (
                  <div className="p-2 text-center">
                    <BounceLoader color="#000" />
                  </div>
                ) : suburbs.length > 0 ? (
                  suburbs.map((suburb) => (
                    <div
                      key={suburb.Suburb}
                      className="p-2 cursor-pointer hover:bg-gray-200 text-black"
                      onClick={() => handleSelect(suburb)}
                    >
                      {suburb}
                    </div>
                  ))
                ) : (
                  <div className=" "> </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Role
          </label>
          <label className="block text-gray-600 text-sm italic mb-4 bg-yellow-100 p-2 rounded">
            <strong>Note:</strong> You will only be able to post jobs if you
            register as an employer or both.
          </label>
          <div className="flex items-center">
            <input
              type="radio"
              name="role"
              value="worker"
              checked={formData.role === "worker"}
              onChange={handleRoleChange}
              className="mr-2"
            />
            <label className="mr-4">Looking for Work</label>
            <input
              type="radio"
              name="role"
              value="employer"
              checked={formData.role === "employer"}
              onChange={handleRoleChange}
              className="mr-2"
            />
            <label className="mr-4">Employer</label>
            <input
              type="radio"
              name="role"
              value="both"
              checked={formData.role === "both"}
              onChange={handleRoleChange}
              className="mr-2"
            />
            <label>Both</label>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={!isFormValid} // Disable button if form is not valid
          >
            Register
          </button>
        </div>
      </form>
      <div className="mt-4">
        <p className="text-gray-600">
          Already have an account?{" "}
          <button
            onClick={() => navigate("/login")}
            className="text-blue-500 hover:text-blue-700"
          >
            Login here
          </button>
        </p> 
      </div>

      {/* Message Modal */}
      <Modal
        isOpen={showMessageModal}
        onRequestClose={closeModal}
        contentLabel="Registration Status"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center ${
            messageType === "success" ? "border-green-500" : "border-red-500"
          } border`}
        >
          <button
            onClick={closeModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div
            className={`text-4xl mb-4 ${
              messageType === "success" ? "text-green-500" : "text-red-500"
            }`}
          >
            {messageType === "success" ? <FaCheckCircle /> : <FaTimesCircle />}
          </div>
          <h2 className="text-xl font-bold mb-4">{message}</h2>
          <div className="flex gap-4">
            {messageType === "success" && (
              <button
                onClick={() => navigate("/login")}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Login
              </button>
            )}

            <button
              onClick={closeModal}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showMembershipMessageModal}
        onRequestClose={closeMembershipModal}
        contentLabel="Registration Status"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center ${
            messageType === "success" ? "border-green-500" : "border-red-500"
          } border`}
        >
          <button
            onClick={closeMembershipModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div
            className={`text-4xl mb-4 ${
              messageType === "success" ? "text-green-500" : "text-red-500"
            }`}
          >
            {messageType === "success" ? <FaCheckCircle /> : <FaTimesCircle />}
          </div>
          <h3 className="font-bold mb-4">{message}</h3>
          <div className="flex gap-4">
            {messageType === "success" ? (
              <Pricing email={formData.email} />
            ) : (
              <button
                onClick={closeMembershipModal}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Close
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Register;
