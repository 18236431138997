import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaUser, FaUsers, FaMapMarkerAlt } from "react-icons/fa";
import axiosInstance from "../axiosInstance";
import logo from '../images/logo1.png'; // Update the path to your user image logo

const HomePage = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([
    {
      id: 1,
      text: "Bisma is looking for a job.",
      details: "Available for full-time.",
      location: "All Adelaide, SA",
      takeUserTo: "/find-worker",
    },
    {
      id: 2,
      text: "RM Concreting is looking for a Concrete Finisher.",
      details: "Available on weekends.",
      location: "Golden Grove, SA",
      takeUserTo: "/find-work",
    },
    {
      id: 3,
      text: "Varun is seeking a General Labourer position.",
      details: "Available on weekends.",
      location: "All Adelaide, SA",
      takeUserTo: "/find-worker",
    },
    {
      id: 4,
      text: "John is looking for a job.",
      details: "Available for full-time.",
      location: "Greenwith, SA",
      takeUserTo: "/find-worker",
    },
    {
      id: 5,
      text: "Michael is looking for a job.",
      details: "Available on Monday.",
      location: "All Adelaide, SA",
      takeUserTo: "/find-worker",
    },
    {
      id: 6,
      text: "Sam is looking for a job.",
      details: "Available for full-time.",
      location: "Richmond, SA",
      takeUserTo: "/find-worker",
    },
    {
      id: 7,
      text: "Michael is looking for a job.",
      details: "Available on Thursday.",
      location: "All Adelaide, SA",
      takeUserTo: "/find-worker",
    },
    {
      id: 8,
      text: "Sam is looking for a job.",
      details: "Available for full-time.",
      location: "Modella, VIC",
      takeUserTo: "/find-worker",
    },
    {
      id: 9,
      text: "Michael is looking for a  job.",
      details: "Available for full-time.",
      location: "Wynn Vale, SA",
      takeUserTo: "/find-worker",
    },
    {
      id: 10,
      text: "Sam is looking for a job.",
      details: "Available on weekends.",
      location: "Holden Hill, SA",
      takeUserTo: "/find-worker",
    },
  ]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  useEffect(() => {
    const fetchVisitorCount = async () => {
      try {
        const response = await axiosInstance.get("/api/visitorcount");
      } catch (error) {
        console.error("Error fetching visitor count:", error);
      }
    };

    fetchVisitorCount();
  }, []);

  // Auto-rotate cards every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [cards.length]);

  const handleFindWorkClick = () => {
    navigate("/find-work");
  };

  const handleCardClick = (url) => {
    navigate(url); // Navigate to the specified URL
  };

  const handleFindWorkerClick = () => {
    navigate("/find-worker");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 via-gray-100 to-blue-100 p-6 relative overflow-hidden">
      <style>
        {`
          @keyframes slide-left-right {
            0% {
              transform: translateX(-100%);
              opacity: 0;
            }
            10% {
              transform: translateX(0);
              opacity: 1;
            }
            90% {
              transform: translateX(0);
              opacity: 1;
            }
            100% {
              transform: translateX(100%);
              opacity: 0;
            }
          }

          .animate-slide-left-right {
            animation: slide-left-right 5s ease-in-out infinite;
          }
        `}
      </style>

      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center opacity-30"
        style={{ backgroundImage: "url(https://source.unsplash.com/random)" }}
      ></div>
      <div className="absolute inset-0 opacity-40"></div>

      <div className="relative z-10 text-center mb-8 p-6 bg-white rounded-lg shadow-lg border border-gray-300 max-w-lg mx-auto">
        <div className="">
        <img src={logo} alt="logo" className="h-16 w-22 md:h-20 md:w-25 md:mb-2 inline-block mr-2" />

        </div>
        <h1 className="text-lg  font-bold mb-4 text-gray-600">
        Find flexible work that fits your schedule, whether it's picking up
          extra shifts on your free days or filling in when someone calls in
          sick.
          <br />
          <span className="text-blue-400">We've got you covered!</span>
        </h1>
        <p className=" text-gray-700 mb-6">
        Skilled Mates is a platform designed to connect employers with employees through a few easy signup steps. Try it now and experience seamless employment connections!
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <button
            onClick={handleFindWorkerClick}
            className="bg-green-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg hover:bg-green-700 transition duration-300 flex items-center justify-center gap-2 transform hover:scale-105"
          >
            <FaUser size={20} />
            Hire Workers
          </button>
          <button
            onClick={handleFindWorkClick}
            className="bg-blue-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center gap-2 transform hover:scale-105"
          >
            <FaSearch size={20} />
            Find Work
          </button>
        </div>
        <div className="relative z-10 flex flex-col items-center mt-8 overflow-hidden">
          <div
            className="w-80 cursor-pointer h-32 p-4 bg-white rounded-lg shadow-lg shadow-top-bottom transition-transform duration-300 ease-in-out animate-slide-left-right"
            onClick={() => handleCardClick(cards[currentCardIndex].takeUserTo)}
          >
            <h2 className="text-xl font-bold mb-2 text-gray-800">
              {cards[currentCardIndex].text}
            </h2>
            <p className="text-gray-600">{cards[currentCardIndex].details}</p>
            <div className="flex flex-col items-center mt-1">
              <div className="flex">
                <FaMapMarkerAlt className="text-red-600 mr-2" />
                <span className="text-gray-600">
                  {cards[currentCardIndex].location}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
