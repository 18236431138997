import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import FindWork from './pages/FindWork';
import FindWorker from './pages/FindWorker';
import HomePage from './pages/HomePage';
import FeedBack from './pages/FeedBack';
import FeedbackList from './pages/FeedbackList';
import Register from './pages/Register';
import Login from './pages/Login';
import Footer from './components/Footer';
import TermsAndConditions from './pages/termAndConditions';

function App() {
  return (
    <Router>
      <div className="App"> 
        <Navbar />
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/find-worker" element={<FindWorker />} />
          <Route  path="/find-work" element={<FindWork />} />
          <Route  path="/feedback" element={<FeedBack />} />
          <Route  path="/feedbacklist" element={<FeedbackList />} />
          <Route  path="/register" element={<Register />} />
          <Route  path="/login" element={<Login />} /> 
          <Route  path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>  
       
        <Footer />
      </div>
    </Router>
  );
}

export default App;
