import React, { useContext, useState, useEffect } from "react";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaUserFriends,
} from "react-icons/fa"; // Import icons for phone, email, and location
import AuthContext from "../AuthContext";
import Modal from "react-modal"; // Import react-modal for dialogs
import { useNavigate } from "react-router-dom"; // For navigation

const AdvisorCard = ({
  name,
  job,
  phone,
  email,
  location,
  description,
  certificates,
  availability,
  sharePhone,
}) => {
  const { isLoggedOut } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleCopyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text);
    alert(`${type} has been copied to clipboard!`);
  };

  const showActionDialog = () => {
    if (isLoggedOut) {
      ShowSignInOrRegisterDialog();
    }
  };
  // Method to show sign-in or register dialog
  const ShowSignInOrRegisterDialog = () => {
    setModalContent("Please sign in or register to see the contact details.");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white m-4 border border-gray-300 group-hover:scale-105 transform transition-transform duration-300">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{job}</div>
        <div className="font-semibold text-sm mb-2">{name}</div>
        <div className="flex items-center mb-4">
          <FaMapMarkerAlt
            size={14}
            className="text-blue-600 mr-2"
            title={location}
          />
          <span className="text-gray-600 text-sm ">{location}</span>
        </div>

        <div className="flex items-center mb-2">
          {sharePhone && phone && !isLoggedOut && (
            <div
              className="flex items-center mr-2 cursor-pointer"
              onClick={() => handleCopyToClipboard(phone, "Phone number")}
            >
              <FaPhoneAlt size={20} className="text-red-600 " />
              <span className="text-gray-700 text-base  ml-2">
                <strong>{phone}</strong>
              </span>
            </div>
          )}
          {isLoggedOut && (
            <div
              className="flex items-center mr-2 cursor-pointer"
              onClick={() => showActionDialog()}
            >
              <FaPhoneAlt size={20} className="text-red-600 " />
              <span
                className="text-gray-700 text-base  ml-2"
                style={{ filter: "blur(2.5px)" }}
              >
                <strong>0701824243</strong>
              </span>
            </div>
          )}
        </div>

        <div className="flex items-center mb-2">
          {email && !isLoggedOut && (
            <div
              className="mr-2 flex items-center cursor-pointer"
              onClick={() => handleCopyToClipboard(email, "Email address")}
            >
              <FaEnvelope size={20} className="text-yellow-600 " />
              <span className="text-gray-700 text-base ml-2">
                <strong>{email}</strong>
              </span>
            </div>
          )}
          {isLoggedOut && (
            <div
              className="mr-2 flex items-center cursor-pointer"
              onClick={() => showActionDialog()}
            >
              <FaEnvelope size={20} className="text-yellow-600 " />
              <span
                className="text-gray-700 text-base ml-2"
                style={{ filter: "blur(2.5px)" }}
              >
                <strong>sample@asmail.com</strong>
              </span>
            </div>
          )}
        </div>

        <div className="mt-4">
          <h3 className="font-semibold text-lg mb-2">Availability</h3>
          <div className="grid grid-cols-3 gap-2">
            {days.map((day, index) => (
              <Tooltip
                key={index}
                title={
                  availability[day]
                    ? `This job is available on ${day}`
                    : `This job is not available on ${day}`
                }
                position="top"
                trigger="mouseenter"
                arrow={true}
              >
                <span
                  className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2 ${
                    availability[day]
                      ? "bg-green-200 text-green-700"
                      : "bg-red-200 text-red-700"
                  }`}
                >
                  {day}
                </span>
              </Tooltip>
            ))}
          </div>
        </div>

        <div className="mt-4">
          <h3 className="font-semibold text-lg mb-2">Certificates required</h3>

          {certificates.filter((certificate) => /[a-zA-Z]/.test(certificate))
            .length > 0 ? (
            certificates
              .filter((certificate) => /[a-zA-Z]/.test(certificate))
              .map((certificate, index) => (
                <span
                  key={index}
                  className="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-blue-700 mr-2 mb-2"
                >
                  {certificate}
                </span>
              ))
          ) : (
            <p className="text-gray-700 text-base">No certificates required.</p>
          )}
        </div>

        <div className="mt-4">
          <fieldset className="border border-gray-300 p-2 rounded mb-4">
            <legend className="text-sm text-gray-700">
              <strong>Notes</strong>
            </legend>
            {description ? (
              <p className="text-gray-700">{description}</p>
            ) : (
              <p className="text-gray-900 text-base mb-2">
                {name} is looking for {job}
              </p>
            )}
          </fieldset>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between mt-4">
          {sharePhone && phone && !isLoggedOut && (
            <a href={`tel:${phone}`} title={`Call ${phone}`}>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Phone
              </button>
            </a>
          )}
          {isLoggedOut && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => showActionDialog()}
            >
              Phone
            </button>
          )}
          {email && !isLoggedOut && (
            <a href={`mailto:${email}`} title={`Email ${email}`}>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Email
              </button>
            </a>
          )}

          {isLoggedOut && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => showActionDialog()}
            >
              Email
            </button>
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="User Action Dialog"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center border-green-500">
          <button
            onClick={closeModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div className="text-yellow-500 text-4xl mb-4">
            <FaUserFriends />
          </div>
          <h2>{modalContent}</h2>
          <div className="flex">
            {modalContent.includes("sign in or register") && (
              <button
                onClick={() => {
                  closeModal();
                  navigate("/login");
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
              >
                Sign In
              </button>
            )}
            {modalContent.includes("sign in or register") && (
              <button
                onClick={() => {
                  closeModal();
                  navigate("/register");
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
              >
                Register
              </button>
            )}

            <button
              onClick={closeModal}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdvisorCard;
