import React, { useState } from 'react';
import axiosInstance from '../axiosInstance';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/api/addEmailIntoNewsletter', { email });
      if (response.status === 200) {
        setMessage('Thank you for subscribing!');
      } else {
        setMessage('Something went wrong. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
    setEmail(''); // Clear the input field after submission
  };

  return (
    <footer className="bg-gray-800 text-white py-4 text-center">
      <div className="flex justify-center space-x-4">
        <p>© 2024 Skilled Mates. All rights reserved.</p>
        <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">Terms and Conditions</a>
        <a href="/feedback" rel="noopener noreferrer" className="text-blue-400 hover:underline">Feedback</a>
        <a href="/feedback" rel="noopener noreferrer" className="text-blue-400 hover:underline">Contact us</a>
      </div>

      <div className="mt-4">
  <label className="block text-white text-sm mb-2">
    Subscribe to get news on new features and updates
  </label>
  <form onSubmit={handleEmailSubmit} className="flex justify-center items-center space-x-2">
    <input
      type="email"
      value={email}
      onChange={handleEmailChange}
      placeholder="Enter your email"
      className="px-4 py-2 rounded-l-md text-gray-800"
      required
    />
    <button
      type="submit"
      className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-r-md"
    >
      Subscribe
    </button>
  </form>
  {message && <p className="mt-2 text-green-400">{message}</p>}
</div>
    </footer>
  );
};

export default Footer;
