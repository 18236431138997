import React, { createContext, useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [isSubscriptionRequired, setisSubscriptionRequired] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    if (token && user) {
      verifyToken(token);
    } else {
      setIsLoggedIn(false);
      setIsLoggedOut(true);
      setUserRole(null);
    }
  }, []);

  const verifyToken = async (token) => {
    try {
      const response = await axiosInstance.post("/api/login/verifyToken", {
        token,
      }); 
      if (response.status === 200) {
        setIsLoggedIn(true);
        setIsLoggedOut(false);
        // setUserRole(response.data.user.role);
      } else {
        logout();
      }
    } catch (error) {
      console.error("Token verification failed:", error);
      logout();
    }
  };

  const login = (UserObj) => {
    localStorage.setItem("token", UserObj.token);
    localStorage.setItem("user", JSON.stringify(UserObj.user));
    setIsLoggedIn(true);
    checkIfSubscriptionIsRequired(UserObj);
    setUserRole(UserObj.user.role); // Set user rol
    setIsLoggedOut(false);
  };

  const checkIfSubscriptionIsRequired = (UserObj) => {
    if (UserObj.user.role === "employer" || UserObj.user.role === "both") {
      const membershipExpires = UserObj.user.membershipExpires
        ? new Date(UserObj.user.membershipExpires)
        : null;
      const currentDate = new Date();

      if (membershipExpires === null || membershipExpires < currentDate) {
        setisSubscriptionRequired(true);
      } else {
        setisSubscriptionRequired(false);
      }
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsLoggedIn(false);
    setUserRole(null);
    setIsLoggedOut(true);
    setisSubscriptionRequired(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isLoggedOut,
        userRole,
        login,
        logout,
        isSubscriptionRequired,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
