import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUserTie, FaSearch, FaSignInAlt,FaSignOutAlt,FaUserPlus } from 'react-icons/fa'; // Import icons
import AuthContext from '../AuthContext';
import logo from '../images/logo.png'; 

const Navbar = () => {
  const { isLoggedIn, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
   

  const handleMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };


  const handleLogout = () => {
    logout();
    navigate('/');
    
  }

  return (
    <nav className="bg-gray-800">
      <div className="container mx-auto flex justify-between items-center ">
        {/* Logo or title */}
        <div >
        <Link to="/" className="flex items-center text-white text-lg font-bold">
          <img src={logo} alt="logo" className="h-12 w-12 md:h-20 md:w-20 inline-block mr-2" />
          <span className="hidden md:inline">Skilled Mates</span>
        </Link>

        </div>
        
        {/* Menu Button for Mobile */}
        <div className="lg:hidden mr-3">
          <button
            onClick={handleMenuToggle}
            className="text-white focus:outline-none"
            id="menu-button"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>

        {/* Navbar Links - Visible on larger screens, hidden on small screens */}
        <div className="hidden lg:flex lg:space-x-4">
          <Link to="/find-worker">
            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2">
              <FaUserTie size={20} />
              Hire Workers
            </button>
          </Link>

          <Link to="/find-work">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2">
              <FaSearch size={20} />
              Find Work
            </button>
          </Link>

          {/* <Link to="/feedback">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2">
              <FaCommentDots size={20} />
              Feedback
            </button>
          </Link> */}
          {isLoggedIn ? (
                      <button
                        onClick={handleLogout}
                        className="bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded flex items-center gap-2"
                      >
                        <FaSignOutAlt size={20} />
                        Logout
                      </button>
                    ) : (
                      <>
                       <Link to="/register">
                      <button className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded flex items-center gap-2 ml-2">
                        <FaUserPlus size={20} />
                        Register
                      </button>
                    </Link> 
                      <Link to="/login">
                        <button className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded flex items-center gap-2">
                          <FaSignInAlt size={20} />
                          Login
                        </button>
                      </Link>
                     
                    </>
                     
                    )} 
        </div>

      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden mr-2">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/find-worker" onClick={handleMenuToggle}>
              <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-2">
                <FaUserTie size={20} />
                Hire Workers
              </button>
            </Link>

            <Link to="/find-work" onClick={handleMenuToggle}>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-1">
                <FaSearch size={20} />
                Find Work
              </button>
            </Link>

            {/* <Link to="/feedback" onClick={handleMenuToggle}>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-1">
                <FaCommentDots size={20} />
                Feedback
              </button>
            </Link> */}

            {isLoggedIn ? (
                      <button
                        onClick={handleLogout}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-1"
                      >
                        <FaSignOutAlt size={20} />
                        Logout
                      </button>
                    ) : (
                      <>
                      <Link to="/register" onClick={handleMenuToggle}>
                        <button className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-1">
                          <FaUserPlus size={20} />
                          Register
                        </button>
                      </Link>
                      <Link to="/login" onClick={handleMenuToggle}>
                        <button className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded w-full flex items-center gap-2 mt-1">
                          <FaSignInAlt size={20} />
                          Login
                        </button>
                      </Link>
                      </>
                    )}

            
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
