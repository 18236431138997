import React, { useState, useEffect, useCallback, useContext } from "react";
import Select from "react-select";
import Modal from "react-modal";
import WorkerCard from "../components/WorkerCard";
import FormForAdviser from "../components/FormForAdviser";
import Spinner from "../components/Spinner";
import {
  FaPlus,
  FaCheckCircle,
  FaTimesCircle,
  FaTimes,
  FaUserFriends,
  FaSync
} from "react-icons/fa";
import axiosInstance from "../axiosInstance";
import debounce from "lodash/debounce";
import AuthContext from "../AuthContext";
import { useNavigate } from "react-router-dom"; // For navigation
import Pricing from "../components/Pricing";

// Custom styles for Select component
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "38px",
    height: "auto",
    borderColor: state.isFocused ? "#4a90e2" : provided.borderColor,
    boxShadow: state.isFocused ? "0 0 0 1px #4a90e2" : provided.boxShadow,
    "&:hover": {
      borderColor: "#4a90e2",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#e0e0e0",
    borderRadius: "4px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#333",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#333",
    ":hover": {
      backgroundColor: "#e0e0e0",
      color: "#f00",
    },
  }),
};

const FindWork = () => {
  const navigate = useNavigate();
  // Context
  const { userRole, isLoggedOut, isSubscriptionRequired, logout } =
    useContext(AuthContext);
  // State declarations
  const [showForm, setShowForm] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("success");
  const [advisers, setAdvisers] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);
  const [badgeOptions, setBadgeOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [availabilityOptions, setAvailabilityOptions] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [selectedAvailability, setSelectedAvailability] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locationInput, setLocationInput] = useState("");
  const [jobInput, setJobInput] = useState("");
  const [certificateInput, setCertificateInput] = useState("");
  const [filteredLoading, setFilteredLoading] = useState(false);
  const [isEmployerAllowedModalOpen, setIsEmployerAllowedModalOpen] =
    useState(false);
  const [signinRegisterModalOpen, setSigninRegisterModalOpen] = useState(false);
  const [isSubscriptionDialogOpen, setShowSubscriptionRequiredDialog] =
    useState(false);

  // Retrieve user email from local storage
  const user = JSON.parse(localStorage.getItem("user"));
  const userEmail = user ? user.email : null;

  // Fetch data from the backend
  useEffect(() => { 

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const locationOptionsResponse = await axiosInstance.get(`/api/suburbs`);
      const formattedLocations = locationOptionsResponse.data.map(
        (location) => ({ value: location, label: location })
      );
      setLocationOptions(formattedLocations);

      const certificateOptionsResponse = await axiosInstance.get(
        `/api/certificateOptions`
      );
      const formattedBadgeOptions =
        certificateOptionsResponse.data.CertificateOptions.map((certi) => ({
          value: certi,
          label: certi,
        }));
      setBadgeOptions(formattedBadgeOptions);

      // Make this hardcoded instead of fetching from the backend
      const formattedAvailabilityOptions = [
        { value: "Monday", label: "Monday" },
        { value: "Tuesday", label: "Tuesday" },
        { value: "Wednesday", label: "Wednesday" },
        { value: "Thursday", label: "Thursday" },
        { value: "Friday", label: "Friday" },
        { value: "Saturday", label: "Saturday" },
        { value: "Sunday", label: "Sunday" },
      ];
      setAvailabilityOptions(formattedAvailabilityOptions);

      //wait 3 seconds before fetching data for advisers
      await new Promise((resolve) => setTimeout(resolve, 3000));

      const advisersResponse = await axiosInstance.get(
        `/api/findworker/workers`
      );
      setAdvisers(advisersResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCertificateOptions = async (inputValue) => {
    try {
      const response = await axiosInstance.get(`/api/certificateOptions`, {
        params: { query: inputValue },
      });
      const formattedBadges = response.data.CertificateOptions.map((badge) => ({
        value: badge,
        label: badge,
      }));
      setBadgeOptions(formattedBadges);
    } catch (error) {
      console.error("Error fetching certificate options:", error);
    }
  };

  const debouncedFetchCertificates = useCallback(
    debounce(fetchCertificateOptions, 300),
    []
  );

  useEffect(() => {
    debouncedFetchCertificates(certificateInput);
  }, [certificateInput, debouncedFetchCertificates]);

  const fetchJobOptions = async (inputValue) => {
    try {
      const response = await axiosInstance.get(`/api/jobRoles/getRoles`, {
        params: { query: inputValue },
      });
      const formattedJobs = response.data.JobRoles.map((job) => ({
        value: job,
        label: job,
      }));
      setJobOptions(formattedJobs);
    } catch (error) {
      console.error("Error fetching job options:", error);
    }
  };

  const debouncedFetchJobs = useCallback(debounce(fetchJobOptions, 300), []);

  useEffect(() => {
    debouncedFetchJobs(jobInput);
  }, [jobInput, debouncedFetchJobs]);

  // Debounced function to fetch locations based on input
  const fetchLocationOptions = async (inputValue) => {
    try {
      const response = await axiosInstance.get(`/api/suburbs`, {
        params: { query: inputValue },
      });
      const formattedLocations = response.data.map((location) => ({
        value: location,
        label: location,
      }));
      setLocationOptions(formattedLocations);
    } catch (error) {
      console.error("Error fetching location options:", error);
    }
  };

  const debouncedFetchLocations = useCallback(
    debounce(fetchLocationOptions, 300),
    []
  );

  useEffect(() => {
    debouncedFetchLocations(locationInput);
  }, [locationInput, debouncedFetchLocations]);

  const handleAdvertiseClick = () => {
    axiosInstance.get("/api/postajobcount");

    if (isLoggedOut) {
      showSignRegisterInModal();
      return;
    }

    if (isSubscriptionRequired) {
      ShowSubscriptionRequiredDialog();
      return;
    }

    if (
      (userRole === "employer" || userRole === "both") &&
      !isSubscriptionRequired
    ) {
      setShowForm(true);
      return;
    } else {
      isEmployerAllowedModal();
    }
  };

  const closeFormModal = () => {
    setShowForm(false);
  };

  const closeMessageModal = () => {
    setShowMessageModal(false);
  };

  const handleFormSubmit = async (data) => {
    try {
      await axiosInstance.post(`/api/findworker/submit`, data);
      setMessage("Data submitted successfully");
      setMessageType("success");
    } catch (error) {
      setMessage("Error submitting data");
      setMessageType("error");
    } finally {
      closeFormModal();
      setShowMessageModal(true);
    }
  };

  useEffect(() => {
    if (!loading) {
      setFilteredLoading(true);
      setTimeout(() => setFilteredLoading(false), 1000); // Simulate a delay for loading filtered workers
    }
  }, [
    selectedJobs,
    selectedBadges,
    selectedAvailability,
    selectedLocations,
    loading,
  ]);

  const filteredWorkers = advisers.filter((worker) => {
    const jobMatch =
      selectedJobs.length === 0 ||
      selectedJobs.some((job) => job.value === worker.job);
    const badgeMatch =
      selectedBadges.length === 0 ||
      selectedBadges.every((badge) =>
        worker.certificates.includes(badge.value)
      );
    const availabilityMatch =
      selectedAvailability.length === 0 ||
      selectedAvailability.every((day) => worker.availability[day.value]);

    const locationMatch =
      selectedLocations.length === 0 ||
      selectedLocations.some((location) => {
        if (location.value === "All Adelaide, South Australia") {
          return worker.location.includes("South Australia");
        }
        return location.value === worker.location;
      });

    return jobMatch && badgeMatch && availabilityMatch && locationMatch;
  });

  const isEmployerAllowedModal = () => {
    setIsEmployerAllowedModalOpen(true);
  };

  const onIsEmployerAllowedCloseModal = () => {
    setIsEmployerAllowedModalOpen(false);
  };

  const showSignRegisterInModal = () => {
    setSigninRegisterModalOpen(true);
  };

  const closeSignRegisterInModal = () => {
    setSigninRegisterModalOpen(false);
  };

  const ShowSubscriptionRequiredDialog = () => {
    setShowSubscriptionRequiredDialog(true);
  };

  const closeSubscriptionDialog = () => {
    setShowSubscriptionRequiredDialog(false);
  };

  const handleSubscriptionEvent = (eventType) => {
    if (eventType === "subscribe") {
      logout();
    } else if (eventType === "later") {
      closeSubscriptionDialog();
    }
  };
  const handleRefresh = async () => {
    setLoading(true);
    fetchData();
  }


  return (
    <div className="p-4 bg-white">
      <h1 className="text-2xl font-bold mb-4"> Find the Perfect Workers for Your Needs </h1>
      <div className="flex flex-wrap">
        <Select
          isMulti
          options={jobOptions}
          value={selectedJobs}
          onChange={setSelectedJobs}
          className="border p-2 mb-4 w-80"
          placeholder="Select Jobs (Start Typing..)"
          onInputChange={(inputValue) => setJobInput(inputValue)}
          styles={customStyles}
          isDisabled={loading} // Disable while loading
        />
        <Select
          isMulti
          options={badgeOptions}
          value={selectedBadges}
          onChange={setSelectedBadges}
          onInputChange={(inputValue) => setCertificateInput(inputValue)}
          className="border p-2 mb-4 w-80"
          placeholder="Select Certificates (Start Typing..)"
          styles={customStyles}
          isDisabled={loading} // Disable while loading
        />
        <Select
          isMulti
          options={availabilityOptions}
          value={selectedAvailability}
          onChange={setSelectedAvailability}
          className="border p-2 mb-4 w-80"
          placeholder="Available Days"
          styles={customStyles}
          isDisabled={loading} // Disable while loading
        />
        <Select
          isMulti
          options={locationOptions}
          value={selectedLocations}
          onChange={setSelectedLocations}
          onInputChange={(inputValue) => setLocationInput(inputValue)}
          className="border p-2 mb-4 w-80"
          placeholder="Select Location (Start Typing..)"
          styles={customStyles}
          isDisabled={loading} // Disable while loading
        />
      </div>
      <div className="flex">
        <button
          onClick={handleAdvertiseClick}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2"
          disabled={loading} // Disable while loading
        >
          <FaPlus size={20} />
          Post a Job
        </button>
        <button
          onClick={handleRefresh}
          className="bg-blue-500 ml-3 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2"
          disabled={loading} // Disable while loading
        >
          <FaSync size={20} />
          Refresh
        </button>
      </div>

      {filteredLoading || loading ? (
        <Spinner /> // Show spinner while filtered workers are loading
      ) : (
        <>
          {filteredWorkers.length === 0 ? (
            <div className="text-center my-4">
              <p>
                Sorry, but more people will be joining with this role soon.
                Please come back later or give us feedback{" "}
                <a href="/feedback" className="text-blue-500 underline">
                  here
                </a>
                .
              </p>
            </div>
          ) : (
            <div className="flex flex-wrap mb-4">
              {filteredWorkers.map((worker, index) => (
                <div
                  key={index}
                  className="group transform transition-transform duration-300"
                >
                  <WorkerCard
                    name={worker.name}
                    job={worker.job}
                    phone={worker.phone}
                    email={worker.email}
                    gender={worker.gender}
                    location={worker.location}
                    description={worker.description}
                    certificates={worker.certificates}
                    availability={worker.availability}
                    sharePhone={worker.sharePhone}
                    policeCheck={worker.policeCheck}
                    yearsExperience={worker.yearsExperience}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {/* Form Modal */}
      <Modal
        isOpen={showForm}
        onRequestClose={closeFormModal}
        contentLabel="Worker Form"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg mx-auto max-h-[90vh] overflow-y-auto">
          <button
            onClick={closeFormModal}
            className="float-right text-gray-500 hover:text-gray-700"
          >
            <FaTimes size={20} />
          </button>
          <FormForAdviser onSubmit={handleFormSubmit} />
        </div>
      </Modal>

      {/* Message Modal */}
      <Modal
        isOpen={showMessageModal}
        onRequestClose={closeMessageModal}
        contentLabel="Submission Status"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center ${
            messageType === "success" ? "border-green-500" : "border-red-500"
          } border`}
        >
          <button
            onClick={closeMessageModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <FaTimes size={20} />
          </button>
          {messageType === "success" ? (
            <FaCheckCircle size={48} className="text-green-500 mb-4" />
          ) : (
            <FaTimesCircle size={48} className="text-red-500 mb-4" />
          )}
          <h2 className="text-lg font-bold mb-2">
            {messageType === "success" ? "Success" : "Error"}
          </h2>
          <p className="text-center">{message}</p>
          <button
            onClick={closeMessageModal}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isEmployerAllowedModalOpen}
        onRequestClose={isEmployerAllowedModal}
        contentLabel="User Action Dialog"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center border-green-500">
          <button
            onClick={onIsEmployerAllowedCloseModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div className="text-yellow-500 text-4xl mb-4">
            <FaUserFriends />
          </div>
          <h2>Only Employers are allowed to post a job</h2>
          <div className="flex">
            <button
              onClick={onIsEmployerAllowedCloseModal}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={signinRegisterModalOpen}
        onRequestClose={closeSignRegisterInModal}
        contentLabel="User Action Dialog"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center border-green-500">
          <button
            onClick={closeSignRegisterInModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div className="text-yellow-500 text-4xl mb-4">
            <FaUserFriends />
          </div>
          <h2> Looking for reliable casual staff to join your team? Register now to easily connect with skilled professionals ready to meet your needs. Start building your ideal team today! </h2>
          <div className="flex">
            <button
              onClick={() => navigate("/login")}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
            >
              Sign In
            </button>
            <button
              onClick={() => navigate("/register")}
              className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
            >
              Register
            </button>
            <button
              onClick={closeSignRegisterInModal}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isSubscriptionDialogOpen}
        onRequestClose={closeSubscriptionDialog}
        contentLabel="Subscription Dialog"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center border-green-500border`}
        >
          <button
            onClick={closeSubscriptionDialog}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div className={`text-4xl mb-4  text-green-500`}>
            <FaCheckCircle />
          </div>
          <h3 className="font-bold mb-4">
            Subscription required to post a job, please subscribe in easy steps
          </h3>
          <div className="flex gap-4">
            <Pricing
              email={userEmail}
              laterButton={true}
              onEvent={handleSubscriptionEvent}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FindWork;
